<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0 text-center" href="javascript:void(0);" routerLink="/consulta/acciones" >Consulta Única Social</a>
    <div class="btn-group" dropdown placement="bottom right">
        <button id="button-basic" dropdownToggle type="button" style="color:azure" class="btn btn-link dropdown-toggle" aria-controls="dropdown-basic">
            <i class="mdi mdi-settings mdi-18px" style="margin-right:8px;"></i>
            <span>{{tokenData.nombre}} {{tokenData.apellido}} </span>
            <span class="caret"></span>
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"  aria-labelledby="button-basic">
            <li role="menuitem" (click)="resetPassword()">
                <a class="dropdown-item" href="javascript:void(0);"><i class="mdi mdi-key "></i> Cambiar Contraseña</a>
            </li>
            <li role="menuitem" (click)="logout()">
                <a class="dropdown-item" href="javascript:void(0);"><i class="mdi mdi-power"></i> Salir</a>
            </li>
        </ul>
    </div>
</nav>