import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';

import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-observar-modal',
  templateUrl: './observar.modal.component.html',
  styleUrls: ['./observar.modal.component.css']
})
export class ObservarModalComponent implements OnInit {

  observacionForm:FormGroup;
  onClose: Subject<any> = new Subject();
  accionSelected:any;

  constructor(private fb:FormBuilder,
              private toastr:ToastrService,
              private bsModalRef: BsModalRef,
              private modalsService:ModalsService) { }

  cancelModal(){
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  submitObservacion(observacionForm:FormGroup) {
    this.modalsService.observarAccion(observacionForm.value).subscribe(data => {
      this.toastr.success('La acción se observó correctamente!','=)' ,{
        progressBar:true,
        positionClass: 'toast-bottom-right',
      });
      this.onClose.next(data);
      this.bsModalRef.hide();
    },error => {
      console.log(error);
    })
  }

  ngOnInit() {
    this.observacionForm = this.fb.group({
      observacion:[,Validators.required],
      idAccion: [this.accionSelected._id,Validators.required]
    });
  }

}
