<div class="modal-header">
    <h4 class="modal-title pull-left"><i class="mdi mdi-key mdi-34px"></i> Cambiar mi Contraseña</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-12">
        <form id="resetPasswordForma" (ngSubmit)="submitPassword(resetPasswordForm)" [formGroup]="resetPasswordForm" autocomplete="off" novalidate>
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input name="password" formControlName="password" type="password" class="form-control" [ngClass]="{'is-invalid':((resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched) || !resetPasswordForm.get('password').valid) && (resetPasswordForm.get('password').value != resetPasswordForm.get('repassword').value),
                                        'is-valid':((resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched) && resetPasswordForm.get('password').valid) && (resetPasswordForm.get('password').value == resetPasswordForm.get('repassword').value)}">
                        <div class="invalid-feedback" *ngIf="!resetPasswordForm.get('password').valid && (resetPasswordForm.get('password').touched || resetPasswordForm.get('password').dirty)">
                            Ingrese un Password mayor a 6 caractéres
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Re password</label>
                        <input name="repassword" formControlName="repassword" type="password" class="form-control" [ngClass]="{'is-invalid':((resetPasswordForm.get('password').dirty || resetPasswordForm.get('repassword').touched) || !resetPasswordForm.get('repassword').valid) && (resetPasswordForm.get('password').value != resetPasswordForm.get('repassword').value),
                                            'is-valid':((resetPasswordForm.get('repassword').dirty || resetPasswordForm.get('repassword').touched) && resetPasswordForm.get('repassword').valid) && (resetPasswordForm.get('password').value == resetPasswordForm.get('repassword').value)}">
                        <div class="invalid-feedback" *ngIf="!resetPasswordForm.get('repassword').valid && (resetPasswordForm.get('repassword').touched || resetPasswordForm.get('repassword').dirty)">
                            Ingrese un Password
                        </div>
                        <div class="invalid-feedback" *ngIf="resetPasswordForm.get('repassword').value != resetPasswordForm.get('password').value">
                            Los Password no Coinciden!
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <div class="w-100">
        <button type="submit" [disabled]="!resetPasswordForm.valid" form="resetPasswordForma" class="btn btn-outline-success">Guardar</button>
        <button type="button" class="btn btn-outline-danger float-right" (click)="cancelModal()">Cancelar</button>
    </div>
</div>