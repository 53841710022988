import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';

import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-resetpassword-modal',
  templateUrl: './resetpassword.modal.component.html'
})
export class ResetPasswordModalComponent implements OnInit {

resetPasswordForm:FormGroup;
  onClose: Subject<any> = new Subject();

  constructor(private fb:FormBuilder,
              private toastr:ToastrService,
              private bsModalRef: BsModalRef,
              private modalsService:ModalsService) { }

  cancelModal(){
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  submitPassword(resetPasswordForm:FormGroup) {
    this.modalsService.resetPassword(resetPasswordForm.value).subscribe(data => {
      this.toastr.success('Tu contraseña se actualizó correctamente!','=)' ,{
        progressBar:true,
        positionClass: 'toast-bottom-right',
      });
      this.onClose.next(data);
      this.bsModalRef.hide();
    },error => {
      console.log(error);
    })
  }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      password:[,[Validators.required,Validators.minLength(6)]],
      repassword:[,[Validators.required,Validators.minLength(6)]],
    });
  }

}
