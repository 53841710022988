import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ResetPasswordModalComponent } from '../../modals/resetpassword/resetpassword.modal.component';

import { UsuariosService } from '../../usuarios/usuarios.service';
import { TokenProvider } from '../../providers/token.provider';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
  providers: [UsuariosService]
})
export class TopNavComponent implements OnInit {

  tokenData:any;
  puntoDeConsultaSelected:any;

  constructor(private router:Router,
              private modalService: BsModalService,
              private tokenProvider:TokenProvider) { }

  resetPassword() {

    let bsModalRef = <BsModalRef>this.modalService.show(ResetPasswordModalComponent,{backdrop:"static",class: 'modal-md'});
    bsModalRef.content.onClose.subscribe(data => {
      if(data) {
       
      }
    });
  }
    
  logout(){
    this.tokenProvider.deleteToken();
    this.router.navigateByUrl('/');
  }

  ngOnInit() {
    this.tokenData = this.tokenProvider.getTokenData();
  }

}
