import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import config from '../config';

@Injectable()

export class UsuariosService {

  constructor(private http: HttpClient) { }

  login(usuario):Observable<any>{
    return this.http.post(`${config.url}/usuarios/login`,usuario).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  selectPuntoDeConsulta(puntoDeConsulta):Observable<any>{
    return this.http.get(`${config.url}/usuarios/selectpuntodeconsulta/${puntoDeConsulta}`).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  getAccionesNoFirmadas():Observable<any[]>{
    return this.http.get(`${config.url}/personas/accionesnofirmadas`).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  signAction(signRequest):Observable<any>{
    return this.http.post(`${config.url}/personas/signaction`,signRequest).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  signActionSinGDE(signRequest):Observable<any>{
    return this.http.post(`${config.url}/personas/signactionsingde`,signRequest).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  getPDFAccion(idAccion):Observable<any>{
    return this.http.get(`${config.url}/personas/getpdf/${idAccion}`,{responseType: 'blob'}).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  getSearchUsuarios(query:string):Observable<any[]>{
    return this.http.get(`${config.url}/usuarios/search/${query}`).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  getUsuario(idUsuario:string):Observable<any>{
    return this.http.get(`${config.url}/usuarios/${idUsuario}`).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  getUsuariosPaginados(filtros):Observable<any[]> {
    return this.http.post(`${config.url}/usuarios/paginados`,filtros).pipe(
      catchError(e => throwError(e)),
      map((res): any => res)
    );
  };

  newUsuario(usuario):Observable<any> {
    return this.http.post(`${config.url}/usuarios`,usuario).pipe(
      catchError(e => throwError(e)),
      map((res): any => res)  
    );
  };

  updateUsuario(usuario):Observable<any> {
    return this.http.put(`${config.url}/usuarios`,usuario).pipe(
      catchError(e => throwError(e)),
      map((res): any => res)  
    );
  };

}
