import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalsModule } from '../modals/modals.module';

import { TopNavComponent } from './top-nav/top-nav.component';
import { LeftNavComponent } from './left-nav/left-nav.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    TopNavComponent, 
    LeftNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    ModalsModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    TopNavComponent,
    LeftNavComponent
  ]
})
export class NavigationModule { }
