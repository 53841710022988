import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import config from '../config';

@Injectable()

export class ModalsService {

  constructor(private http: HttpClient) { }

  getSearchUsuarios(query:string):Observable<any[]>{
    return this.http.get(`${config.url}/usuarios/searchusers/${query}`).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  postSign(objectForSign):Observable<any[]>{
    return this.http.post(`${config.url}/personas/signaction`,objectForSign).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  resetPassword(newPassword):Observable<any[]>{
    return this.http.put(`${config.url}/usuarios/resetpassword`,newPassword).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  newIdentidad(newIdentidad):Observable<any[]>{
    return this.http.post(`${config.url}/personas/identidad`,newIdentidad).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

  observarAccion(observacion):Observable<any>{
    return this.http.post(`${config.url}/personas/observaraccion`,observacion).pipe(catchError(e => throwError(e)),map((res):any => res));
  };  

  getPDFAccion(idAccion):Observable<any>{
    return this.http.get(`${config.url}/personas/getpdf/${idAccion}`,{responseType: 'blob'}).pipe(catchError(e => throwError(e)),map((res):any => res));
  };

}
