<!--
<nav class="col-md-2  bg-light sidebar">
    <div class="sidebar-sticky">
        <ul class="nav flex-column">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='inicio'}" href="javascript:void(0);" routerLink="/inicio">
                    <i class="mdi mdi-home-outline"></i> Inicio
                </a>
            </li>
            <li class="nav-item" *ngIf="lectura">
                <a class="nav-link" [ngClass]="{'active':url =='/personas'}" href="javascript:void(0);" routerLink="/personas">
                    <i class="mdi mdi-account-search-outline"></i> Personas
                </a>
            </li>
            <li class="nav-item" *ngIf="lectura">
                <a class="nav-link" [ngClass]="{'active':url =='/personas/accioneslista'}" href="javascript:void(0);" routerLink="/personas/accioneslista">
                    <i class="mdi mdi-file-search-outline"></i> Acciones
                </a>
            </li>
            <li class="nav-item" *ngIf="consulta">
                <a class="nav-link" [ngClass]="{'active':active =='consulta'}" href="javascript:void(0);" routerLink="/consulta/acciones">
                    <i class="mdi mdi-cloud-search-outline"></i> Consulta
                </a>
            </li>
           
            <li class="nav-item" *ngIf="responsable">
                <a class="nav-link" [ngClass]="{'active':url =='/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios/bandejafirma">
                    <i class="mdi mdi-inbox-arrow-down"></i> Bandeja de Firma
                </a>
            </li>
        </ul>
        <h6 *ngIf="tokenData.admin" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Administración</span>
            <a class="d-flex align-items-center text-muted" href="javascript:void(0);">
                <span data-feather="plus-circle"></span>
            </a>
        </h6>
        <ul *ngIf="tokenData.admin" class="nav flex-column mb-2">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='puntosdeconsulta'}" href="javascript:void(0);" routerLink="/puntosdeconsulta">
                    <i class="mdi mdi-home-city-outline"></i> Puntos de Consulta
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='acciones'}" href="javascript:void(0);" routerLink="/acciones">
                    <i class="mdi mdi-flash-circle"></i> Acciones
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='normas'}" href="javascript:void(0);" routerLink="/normas">
                    <i class="mdi mdi-book-outline"></i> Normas
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='usuarios' && url !='/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios">
                    <i class="mdi mdi-account-group"></i> Usuarios
                </a>
            </li>
        </ul>
    </div>
</nav>
<!--
<nav class="col-md-2 bg-light sidebar">
  <div class="collapse d-md-block" id="sidebarContent">
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='inicio'}" href="javascript:void(0);" routerLink="/inicio">
            <i class="mdi mdi-home-outline"></i> Inicio
          </a>
        </li>
        <li class="nav-item" *ngIf="lectura">
                <a class="nav-link" [ngClass]="{'active':url =='/personas'}" href="javascript:void(0);" routerLink="/personas">
                    <i class="mdi mdi-account-search-outline"></i> Personas
                </a>
            </li>
            <li class="nav-item" *ngIf="lectura">
                <a class="nav-link" [ngClass]="{'active':url =='/personas/accioneslista'}" href="javascript:void(0);" routerLink="/personas/accioneslista">
                    <i class="mdi mdi-file-search-outline"></i> Acciones
                </a>
            </li>
            <li class="nav-item" *ngIf="consulta">
                <a class="nav-link" [ngClass]="{'active':active =='consulta'}" href="javascript:void(0);" routerLink="/consulta/acciones">
                    <i class="mdi mdi-cloud-search-outline"></i> Consulta
                </a>
            </li>
            <li class="nav-item" *ngIf="responsable">
                <a class="nav-link" [ngClass]="{'active':url =='/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios/bandejafirma">
                    <i class="mdi mdi-inbox-arrow-down"></i> Bandeja de Firma
                </a>
            </li>
      </ul>
      <h6 *ngIf="tokenData.admin" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Administración</span>
            <a class="d-flex align-items-center text-muted" href="javascript:void(0);">
                <span data-feather="plus-circle"></span>
            </a>
        </h6>
        <ul *ngIf="tokenData.admin" class="nav flex-column mb-2">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='puntosdeconsulta'}" href="javascript:void(0);" routerLink="/puntosdeconsulta">
                    <i class="mdi mdi-home-city-outline"></i> Puntos de Consulta
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='acciones'}" href="javascript:void(0);" routerLink="/acciones">
                    <i class="mdi mdi-flash-circle"></i> Acciones
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='normas'}" href="javascript:void(0);" routerLink="/normas">
                    <i class="mdi mdi-book-outline"></i> Normas
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active':active =='usuarios' && url !='/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios">
                    <i class="mdi mdi-account-group"></i> Usuarios
                </a>
            </li>
        </ul>
    </div>
  </div>
</nav>
-->
<!-- Botón para dispositivos móviles -->
<button class="btn btn-primary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#sidebarContent" aria-controls="sidebarContent" aria-expanded="false" aria-label="Toggle navigation">
  Menú
</button>

<!-- Barra lateral -->
<nav class="col-md-2 bg-light sidebar">
  <div class="collapse d-md-block" id="sidebarContent">
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='inicio'}" href="javascript:void(0);" routerLink="/inicio">
            <i class="mdi mdi-home-outline"></i> Inicio
          </a>
        </li>
        <li class="nav-item" *ngIf="lectura">
          <a class="nav-link" [ngClass]="{'active': url == '/personas'}" href="javascript:void(0);" routerLink="/personas">
            <i class="mdi mdi-account-search-outline"></i> Personas
          </a>
        </li>
        <li class="nav-item" *ngIf="lectura">
          <a class="nav-link" [ngClass]="{'active': url == '/personas/accioneslista'}" href="javascript:void(0);" routerLink="/personas/accioneslista">
            <i class="mdi mdi-file-search-outline"></i> Acciones
          </a>
        </li>
        <li class="nav-item" *ngIf="consulta">
          <a class="nav-link" [ngClass]="{'active': active =='consulta'}" href="javascript:void(0);" routerLink="/consulta/acciones">
            <i class="mdi mdi-cloud-search-outline"></i> Consulta
          </a>
        </li>
        <li class="nav-item" *ngIf="responsable">
          <a class="nav-link" [ngClass]="{'active': url == '/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios/bandejafirma">
            <i class="mdi mdi-inbox-arrow-down"></i> Bandeja de Firma
          </a>
        </li>
      </ul>
      <h6 *ngIf="tokenData.admin" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Administración</span>
        <a class="d-flex align-items-center text-muted" href="javascript:void(0);">
          <span data-feather="plus-circle"></span>
        </a>
      </h6>
      <ul *ngIf="tokenData.admin" class="nav flex-column mb-2">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='puntosdeconsulta'}" href="javascript:void(0);" routerLink="/puntosdeconsulta">
            <i class="mdi mdi-home-city-outline"></i> Puntos de Consulta
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='acciones'}" href="javascript:void(0);" routerLink="/acciones">
            <i class="mdi mdi-flash-circle"></i> Acciones
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='normas'}" href="javascript:void(0);" routerLink="/normas">
            <i class="mdi mdi-book-outline"></i> Normas
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': active =='usuarios' && url != '/usuarios/bandejafirma'}" href="javascript:void(0);" routerLink="/usuarios">
            <i class="mdi mdi-account-group"></i> Usuarios
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

