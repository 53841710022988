<div class="modal-header">
    <h4 class="modal-title pull-left">Observación</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form id="observacionForma" (ngSubmit)="submitObservacion(observacionForm)" [formGroup]="observacionForm" autocomplete="off" novalidate>
        <div class="form-group">
            <textarea class="form-control" minlength="10" id="observacion" rows="3" formControlName="observacion"></textarea>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <div class="w-100">
        <button type="submit" [disabled]="!observacionForm.valid" form="observacionForma" class="btn btn-outline-success">Observar</button>
        <button type="button" class="btn btn-outline-danger float-right" (click)="cancelModal()">Cancelar</button>
    </div>
</div>