import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';

import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => {
      return import('./inicio/inicio.module').then(m => m.InicioModule);
    },
    canActivate:[AuthGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'inicio',
        loadChildren: () => {
          return import('./home/home.module').then(m => m.HomeModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'personas',
        loadChildren: () => {
          return import('./personas/personas.module').then(m => m.PersonasModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'consulta',
        loadChildren: () => {
          return import('./consulta/consulta.module').then(m => m.ConsultaModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'puntosdeconsulta',
        loadChildren: () => {
          return import('./puntosDeConsulta/puntosDeConsulta.module').then(m => m.PuntosDeConsultaModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'acciones',
        loadChildren: () => {
          return import('./acciones/acciones.module').then(m => m.AccionesModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'normas',
        loadChildren: () => {
          return import('./normas/normas.module').then(m => m.NormasModule);
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'usuarios',
        loadChildren: () => {
          return import('./usuarios/usuarios.module').then(m => m.UsuariosModule);
        },
        canActivate:[AuthGuard]
      },
    ]
},

 /* {
    path: '',
    loadChildren: './inicio/inicio.module#InicioModule'
  },
  {
    path: 'consulta',
    loadChildren: './consulta/consulta.module#ConsultaModule'
  },
  {
    path: 'puntosdeconsulta',
    loadChildren: './puntosDeConsulta/puntosDeConsulta.module#PuntosDeConsultaModule'
  },
  {
    path: 'acciones',
    loadChildren: './acciones/acciones.module#AccionesModule'
  },
  {
    path: 'normas',
    loadChildren: './normas/normas.module#NormasModule'
  },
  {
    path: 'usuarios',
    loadChildren: './usuarios/usuarios.module#UsuariosModule'
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
