import { Injectable } from "@angular/core";

import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})

export class TokenProvider {

  jwt = new JwtHelperService();
  tokenData = new BehaviorSubject(this.jwt.decodeToken(localStorage.getItem('token')));
  token = new BehaviorSubject(localStorage.getItem('token'));
  puntoDeConsulta = new BehaviorSubject(JSON.parse(localStorage.getItem('puntoDeConsulta')));

  constructor() { }

  saveToken(token) {
    localStorage.setItem('token',token);
    this.token.next(token);
    this.tokenData.next(this.jwt.decodeToken(token));
  }

  savePuntoDeConsulta(puntoDeConsulta) {
    localStorage.setItem('puntoDeConsulta',JSON.stringify(puntoDeConsulta));
    this.puntoDeConsulta.next(puntoDeConsulta);
  }

  verifyTokenExpiration(){
    return this.jwt.isTokenExpired(localStorage.getItem('token'));
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getPuntoDeConsulta() {
    return JSON.parse(localStorage.getItem('puntoDeConsulta'));
  }

  getTokenData() {
    return this.jwt.decodeToken(localStorage.getItem('token'));
  }

  getTokenObservable():Observable<any> {
    return this.token;
  }

  getTokenDataObservable():Observable<any> {
    return this.tokenData;
  }

  getPuntoDeConsultaObservable():Observable<any> {
    return this.puntoDeConsulta;
  }

  deleteToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('puntoDeConsulta');
    this.tokenData.next(null);
    this.puntoDeConsulta.next(null);
    this.token.next(null);
  }

}
