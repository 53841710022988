import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { TokenProvider } from './token.provider';
import { Observable,of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {  
    
    constructor(private router:Router,
                private tokenProvider: TokenProvider) {}  
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.tokenProvider.getToken()}`
            }
        });    
        /*return next.handle(request).pipe(catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 403) {
                    this.tokenProvider.deleteToken();
                    this.router.navigateByUrl('/');
                };
            }
            //return of(err);
            return new Observable<HttpEvent<any>>(observer => observer.next(err));
            //return new Observable<HttpEvent<any>>();
        }));*/
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpErrorResponse) {
                    if (event.status === 403) {
                        this.tokenProvider.deleteToken();
                        this.router.navigateByUrl('/');
                    };
                }
                return event;
            }));

    }
}