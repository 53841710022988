import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { Subject } from 'rxjs';

import { ModalsService } from '../modals.service';

import * as _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-identidad-modal',
  templateUrl: './identidad.modal.component.html',
  styleUrls: ['./identidad.modal.component.css']
})
export class IdentidadModalComponent implements OnInit {

  isEmpty = _.isEmpty;
  title:string = "Nueva";
  identidadForm:FormGroup;
  identidadSelected: any;
  idPersona:string;
  idAccion:string;
  imagenes:any[] = [];
  sizeImagenes:number = 0;

  activeSlideIndex = 0;

  onClose: Subject<any> = new Subject();

  constructor(private fb:FormBuilder,
              private toastr:ToastrService,
              private spinner: NgxSpinnerService,
              private localeService:BsLocaleService,
              private bsModalRef: BsModalRef,
              private modalsService:ModalsService) { 
                defineLocale("es", esLocale);
                this.localeService.use("es");
              }



  cargarArchivos(files) {
    const identidadForm = this.identidadForm.get('imagenesDocumento') as FormArray
    _.forEach(files, (value, key) => {
      if (value.type.match(/image\/*/) == null) {
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(value);

      reader.onload = _event => {
        if((this.sizeImagenes + value.size) <= 1000000 || (this.imagenes.length + 1) <= 10) {
          this.sizeImagenes += value.size;
          this.imagenes.push({
            base64: reader.result,
            name: value.name,
            size: value.size,
            tipo: this.identidadForm.get('fileType').value  
          });
          let imagenForm = this.newImagenesForm()
          imagenForm.patchValue({
            base64: reader.result,
            name: value.name,
            size: value.size,
            tipo: this.identidadForm.get('fileType').value
          });
          identidadForm.push(imagenForm);
        }
      };
    });
    this.identidadForm.get("fileInput").setValue("");
  }

  deleteImagen(index) {
    this.imagenes.splice(index,1);
    let imagenesArrayForm = this.identidadForm.get('imagenesDocumento') as FormArray;
    imagenesArrayForm.removeAt(index);
  }

  newImagenesForm():FormGroup {
    return this.fb.group({
      name:  [],
      base64:  [],
      size: []
    });
  }

  cancelModal(){
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  submitIdentidad(identidadForm:FormGroup) {
    let newIdentidad = identidadForm.getRawValue();
    delete newIdentidad.fileInput;
    delete newIdentidad.fileType;
    this.spinner.show();
    this.modalsService.newIdentidad(newIdentidad).subscribe(data => {
      this.spinner.hide();
      this.toastr.success('La identidad CUS se guardó correctamente','=)' ,{
        progressBar:true,
        positionClass: 'toast-bottom-right',
      });
      this.onClose.next(data);
      this.bsModalRef.hide();
    },error => {
      this.spinner.hide();
      console.log(error);
    })
  }

  ngOnInit() {
    
    this.identidadForm = this.fb.group({
      idAccion:[this.idAccion,Validators.required],
      idPersona:[this.idPersona,Validators.required],
      nombres: [,Validators.required],
      apellidos: [,Validators.required],
      fechaNacimiento: [,Validators.required],
      tipoDocumento: ['DNI',Validators.required],
      numeroDocumento: [,Validators.required],
      cuit: [,Validators.required],
      numeroTramite: [,Validators.required],
      sexo: [,Validators.required],
      fileInput: [],
      fileType: ['Documento',],
      imagenesDocumento: this.fb.array([])
    });
    let identidadSelected = Object.assign({}, this.identidadSelected);
    if(identidadSelected) {
      this.identidadForm.markAllAsTouched();
      switch (identidadSelected.bd) {
        case 'identidadSintys':
          this.identidadForm.get('nombres').setValue(identidadSelected.DENO);
          var dateParts =identidadSelected.FECHA_NACIMIENTO.split('/');
          this.identidadForm.get('fechaNacimiento').setValue(new Date(moment().date(dateParts[0]).month(dateParts[1]-1).year(dateParts[2]).toISOString()));
          this.identidadForm.get('tipoDocumento').setValue(identidadSelected.TIPO_DOCUMENTO);
          this.identidadForm.get('numeroDocumento').setValue(identidadSelected.NDOC);
          this.identidadForm.get('cuit').setValue(identidadSelected.CUIL);
          let sexo = identidadSelected.SEXO.toLowerCase();
          this.identidadForm.get('sexo').setValue(sexo.charAt(0).toUpperCase() + sexo.slice(1));
          break;
        case 'identidadAPROS':
          this.identidadForm.get('nombres').setValue(identidadSelected.nombre);
          this.identidadForm.get('apellidos').setValue(identidadSelected.apellido);
          this.identidadForm.get('tipoDocumento').setValue(identidadSelected.tipoDocumento);
          this.identidadForm.get('numeroDocumento').setValue(identidadSelected.numeroDocumento);
          this.identidadForm.get('fechaNacimiento').setValue(new Date(moment.utc(identidadSelected.fechaNacimiento).add(1,'day').toISOString()));
          if(identidadSelected.sexo == 'F'){
            this.identidadForm.get('sexo').setValue('Femenino');
          }else this.identidadForm.get('sexo').setValue('Masculino');
          break;
        case 'identidadDGE':
          this.identidadForm.get('nombres').setValue(identidadSelected.nombre);
          this.identidadForm.get('apellidos').setValue(identidadSelected.apellido);
          this.identidadForm.get('tipoDocumento').setValue(identidadSelected.tipoDocumento);
          this.identidadForm.get('numeroDocumento').setValue(identidadSelected.numeroDocumento);
          this.identidadForm.get('sexo').setValue(identidadSelected.sexo);
          this.identidadForm.get('fechaNacimiento').setValue(new Date(moment.utc(identidadSelected.fechaNacimiento).add(1,'day').toISOString()));
          break;
        case 'identidadCUS':
          this.identidadForm.patchValue(identidadSelected);
          this.identidadForm.get('fechaNacimiento').setValue(new Date(identidadSelected.fechaNacimiento));
          let imagenesDocumentoFormArray = this.identidadForm.get('imagenesDocumento') as FormArray;
          _.forEach(identidadSelected.imagenesDocumento,(value) => {
            let imagenForm = <FormGroup> this.newImagenesForm();
            imagenForm.patchValue(value);
            imagenesDocumentoFormArray.push(imagenForm);
            this.imagenes.push(value);
          })
          break;
      }
    }
  }

}
