<div class="modal-header">
    <h4 class="modal-title pull-left"><i class="mdi mdi-account-plus mdi-34px"></i> {{title}} Identidad CUS</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-12">
        <form id="identidadForma" (ngSubmit)="submitIdentidad(identidadForm)" [formGroup]="identidadForm" autocomplete="off" novalidate>
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="nombres">Nombres</label>
                        <input #input1 (input)="input1.value=$event.target.value.toUpperCase()" name="nombres" formControlName="nombres" type="text" class="form-control" [ngClass]="{'is-invalid':((identidadForm.get('nombres').dirty || identidadForm.get('nombres').touched) && !identidadForm.get('nombres').valid),
                                'is-valid':((identidadForm.get('nombres').dirty || identidadForm.get('nombres').touched) && identidadForm.get('nombres').valid)}">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('nombres').valid && (identidadForm.get('nombres').touched || identidadForm.get('nombres').dirty)">
                            Ingrese al menos un Nombre
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="apellidos">Apellidos</label>
                        <input #input2 (input)="input2.value=$event.target.value.toUpperCase()" name="apellidos" formControlName="apellidos" type="text" class="form-control" [ngClass]="{'is-invalid':((identidadForm.get('apellidos').dirty || identidadForm.get('apellidos').touched) && !identidadForm.get('apellidos').valid),
                                'is-valid':((identidadForm.get('apellidos').dirty || identidadForm.get('apellidos').touched) && identidadForm.get('apellidos').valid)}">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('apellidos').valid && (identidadForm.get('apellidos').touched || identidadForm.get('apellidos').dirty)">
                            Ingrese al menos un Apellido
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fechaNacimiento">Fecha Nacimiento</label>
                        <input name="fechaNacimiento" formControlName="fechaNacimiento" type="text" class="form-control" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [ngClass]="{'is-invalid':((identidadForm.get('fechaNacimiento').dirty || identidadForm.get('fechaNacimiento').touched) && !identidadForm.get('fechaNacimiento').valid),
                            'is-valid':((identidadForm.get('fechaNacimiento').dirty || identidadForm.get('fechaNacimiento').touched) && identidadForm.get('fechaNacimiento').valid)}">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('fechaNacimiento').valid && (identidadForm.get('fechaNacimiento').touched || identidadForm.get('fechaNacimiento').dirty)">
                            Ingrese una Fecha Nacimiento válida
                        </div>
                    </div>
                    <label for="numero">Sexo</label><br>
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-link dropdown-toggle" aria-controls="dropdown-basic">
                            <span *ngIf="!identidadForm.get('sexo').value">Seleccionar </span>
                            <span *ngIf="identidadForm.get('sexo').valid" [innerText]="identidadForm.get('sexo').value"> </span>
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" (click)="identidadForm.get('sexo').setValue('Masculino')">
                                <a class="dropdown-item" href="javascript:void(0);">Masculino</a>
                            </li>
                            <li role="menuitem" (click)="identidadForm.get('sexo').setValue('Femenino')">
                                <a class="dropdown-item" href="javascript:void(0);">Femenino</a>
                            </li>
                        </ul>
                    </div><br>
                    <label for="numero">Documento</label><br>
                    <div class="input-group mb-3">

                        <div class="input-group-prepend" dropdown>
                            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                                <span *ngIf="!identidadForm.get('tipoDocumento').value">Seleccionar </span>
                                <span *ngIf="identidadForm.get('tipoDocumento').valid" [innerText]="identidadForm.get('tipoDocumento').value"> </span>
                                <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('DNI')">
                                    <a class="dropdown-item" href="javascript:void(0);">DNI</a>
                                </li>
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('LC')">
                                    <a class="dropdown-item" href="javascript:void(0);">Libreta Cívica</a>
                                </li>
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('LE')">
                                    <a class="dropdown-item" href="javascript:void(0);">Libreta Enrolamiento</a>
                                </li>
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('Cédula de Identidad Nacional')">
                                    <a class="dropdown-item" href="javascript:void(0);">Cédula de Identidad Nacional</a>
                                </li>
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('Pasaporte Extrangero')">
                                    <a class="dropdown-item" href="javascript:void(0);">Pasaporte Extrangero</a>
                                </li>
                                <li role="menuitem" (click)="identidadForm.get('tipoDocumento').setValue('Cédula de Identidad Extrangera')">
                                    <a class="dropdown-item" href="javascript:void(0);">Cédula de Identidad Extrangera</a>
                                </li>

                            </ul>
                        </div>
                        <input name="documento" mask="00.000.000" formControlName="numeroDocumento" placeholder="Número" type="text" class="form-control" [ngClass]="{'is-invalid':((identidadForm.get('numeroDocumento').dirty || identidadForm.get('numeroDocumento').touched) && !identidadForm.get('numeroDocumento').valid),
                        'is-valid':((identidadForm.get('numeroDocumento').dirty || identidadForm.get('numeroDocumento').touched) && identidadForm.get('numeroDocumento').valid)}">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('numeroDocumento').valid && (identidadForm.get('numeroDocumento').touched || identidadForm.get('numeroDocumento').dirty)">
                            Ingrese una Documento
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="cuit">CUIT</label>
                        <input name="cuit" mask="00-00000000-0" formControlName="cuit" type="text" class="form-control"  [ngClass]="{'is-invalid':((identidadForm.get('cuit').dirty || identidadForm.get('cuit').touched) && !identidadForm.get('cuit').valid),
                            'is-valid':((identidadForm.get('cuit').dirty || identidadForm.get('cuit').touched) && identidadForm.get('cuit').valid)}">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('cuit').valid && (identidadForm.get('cuit').touched || identidadForm.get('cuit').dirty)">
                            Ingrese un CUIT válido
                        </div>
                    </div>
                    <ng-template #popTemplate><img src="assets/ntramite.png" width="240px;"></ng-template>
                    <div class="form-group">
                        <label for="documento">N° Trámite</label>
                        <input name="documento" formControlName="numeroTramite" type="text" class="form-control" [ngClass]="{'is-invalid':((identidadForm.get('numeroTramite').dirty || identidadForm.get('numeroTramite').touched) && !identidadForm.get('numeroTramite').valid),
                                            'is-valid':((identidadForm.get('numeroTramite').dirty || identidadForm.get('numeroTramite').touched) && identidadForm.get('numeroTramite').valid)}" [popover]="popTemplate" triggers="focus" popoverTitle="¿Donde busco el N° Trámite?">
                        <div class="invalid-feedback" *ngIf="!identidadForm.get('numeroTramite').valid && (identidadForm.get('numeroTramite').touched || identidadForm.get('numeroTramite').dirty)">
                            Ingrese un Número de Trámite
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="input-group mb-3" *ngIf="sizeImagenes < 10000000 && imagenes.length < 10">
                        <div class="custom-file">
                            <input #file type="file" formControlName="fileInput" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" accept='image/jpeg,image/png' (change)="cargarArchivos(file.files)">
                            <label class="custom-file-label" for="inputGroupFile01">Subir imágen</label>
                        </div>
                    </div>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" formControlName="fileType" id="customRadioInline1" name="fileType" value="Documento" class="custom-control-input">
                            <label class="custom-control-label" for="customRadioInline1">Documento</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" formControlName="fileType" id="customRadioInline2" name="fileType" value="Foto" class="custom-control-input">
                            <label class="custom-control-label" for="customRadioInline2">Foto Persona</label>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="!isEmpty(imagenes)">

                        <carousel [(activeSlide)]="activeSlideIndex">
                            <slide *ngFor="let imagen of imagenes; let index=index">
                                <img [src]="imagen.base64" alt="image slide" style="display: block; width: 100%;">

                                <div class="carousel-caption">
                                    <a href="javascript:void(0);" (click)="deleteImagen(index)"><i class="text-danger mdi mdi-delete-circle-outline" style="font-size: 40px;"></i></a>
                                    <h4 class="text-primary"><strong>{{imagen.name}}</strong></h4>
                                    <h6 class="text-secondary">{{imagen.tipo}}</h6>
                                </div>
                            </slide>
                        </carousel>
                        <progressbar max="10" [value]="imagenes.length" type="warning" [striped]="true" [animate]="true"><i class="text-body">{{imagenes.length}} / 10 imágenes</i></progressbar>
                        <progressbar max="10000000" [value]="sizeImagenes" type="danger" [striped]="true" [animate]="true"><i class="text-body">{{(sizeImagenes/1000000).toFixed(2)}}mb / 10mb</i></progressbar>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <div class="w-100">
        <button type="submit" [disabled]="!identidadForm.valid || isEmpty(identidadForm.get('imagenesDocumento').value)" form="identidadForma" class="btn btn-outline-success">Guardar</button>
        <button type="button" class="btn btn-outline-danger float-right" (click)="cancelModal()">Cancelar</button>
    </div>
</div>