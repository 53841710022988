<div class="modal-header">
    <div class="modal-title pull-left">
        <h4>
            <i class="mdi mdi-draw mdi-34px"></i> Firmar
        </h4>
        <h5>
            <span class="badge badge-dark">{{accion.accion.nombre}} - {{accion.identidad.nombre}} ({{accion.identidad.documento}})</span>
        </h5>

    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!loading && !gde">
    <form id="firmaForm" (ngSubmit)="submitFirma(firmaForm)" [formGroup]="firmaForm" autocomplete="off" novalidate>
        <div class="col-12">
            <div class="form-group">
                <label for="normas">Destinatario</label>
                <ng-select [items]="dataSearch$ | async" bindValue="_id" placeholder="Buscar destinatario" [clearable]="false" [loading]="searchLoading" loadingText="Buscando..." notFoundText="No se encontró lo que buscas." typeToSearchText="Escribe para buscar" [typeahead]="inputSearch$" formControlName="destinatario">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span>{{item.field}}: <strong>{{ item.description }}</strong></span>
                        <a href="javascript:void(0);"><span class="mdi mdi-close" (click)="clear(item)" aria-hidden="true"></span></a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        {{item.description}} <br />
                        <small>{{item.field}}</small>
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group">
                <div class="custom-control custom-switch">
                    <input type="checkbox" formControlName="detalle" class="custom-control-input" id="detalle">
                    <label class="custom-control-label" for="detalle">Detalle?</label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="justify-content-center" *ngIf="loading">
    <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
    </div>
</div>

<div class="modal-body text-center" *ngIf="!loading && gde">
    <span>
        <i class="mdi mdi-file-pdf-outline mdi-24px"></i> <a href="javascript:void(0);" [innerText]="gde.numeroGDE" (click)="getPDF(gde.idAccion)"></a>
    </span>
    
</div>

<div class="modal-footer justify-content-between" *ngIf="!loading && !gde">
    <div class="w-100">
        <button type="submit" form="firmaForm" [disabled]="!firmaForm.valid || loading" class="btn btn-outline-success">Firmar</button>
        <button type="button" class="btn btn-outline-danger float-right" (click)="cancelModal()">Cancelar</button>
    </div>
</div>