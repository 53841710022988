import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';

import { IdentidadModalComponent } from './identidad/identidad.modal.component';
import { FirmarComponent } from './firmar/firmar.component';
import { ObservarModalComponent } from './observar/observar.modal.component';
import { ResetPasswordModalComponent } from './resetpassword/resetpassword.modal.component';

import { ModalsService } from './modals.service';



@NgModule({
  declarations: [
    IdentidadModalComponent,
    FirmarComponent,
    ObservarModalComponent,
    ResetPasswordModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgSelectModule
  ],
  providers:[
    ModalsService
  ],
  exports:[
    IdentidadModalComponent,
    FirmarComponent,
    ObservarModalComponent,
    ResetPasswordModalComponent
  ]
})
export class ModalsModule { }
