import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TokenProvider } from '../../providers/token.provider';

import * as _ from 'lodash';
@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css']
})
export class LeftNavComponent implements OnInit {

  tokenData:any;
  active:string;
  url:string;
  responsable:boolean = false;
  lectura:boolean = false;
  consulta:boolean = false;

  constructor(private tokenProvider:TokenProvider,
              private router:Router) { }

  ngOnInit() {
    this.tokenData = this.tokenProvider.getTokenData();
    if (this.tokenData.admin) {
      this.responsable = true;
      this.lectura = true;
      this.consulta = true;
    }else {
      _.forEach(this.tokenData.permisos,value => {
        if(value.responsable) {
          this.responsable = true;
        }
        if(value.lectura) {
          this.lectura = true;
        }
        if(value.consulta) {
          this.consulta = true;
        }
      });
    }
    
    this.active = this.router.url.split('/')[1];
    this.url = this.router.url;
    this.router.events.subscribe(data => {
      if(data['url']) {
        this.url = data['url'];
        this.active = data['url'].split('/')[1];
      }
    })
  }

}
