import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Observable,Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-firmar',
  templateUrl: './firmar.component.html',
  styleUrls: ['./firmar.component.css']
})
export class FirmarComponent implements OnInit {

  onClose: Subject<any> = new Subject();
  accion:any;
  loading:boolean = false;

  dataSearch$:Observable<any[]>;
  searchLoading = false;
  inputSearch$ = new Subject<string>();
  firmaForm:FormGroup;
  gde:any;

  constructor(private fb:FormBuilder,
              private toastr:ToastrService,
              private bsModalRef: BsModalRef,
              private modalsService:ModalsService) { }

  cancelModal(){
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  loadSearcher(){
    this.inputSearch$.subscribe(query => {
      if(query){
        if(query.length >= 1 ){
          this.searchLoading = true;
          this.dataSearch$ = this.modalsService.getSearchUsuarios(query).pipe(
            tap(() => this.searchLoading = false)
          );
        }
      }
    });
  }

  changeLoading() {
    this.loading = !this.loading;
  }

  getPDF(idAccion) {
    this.modalsService.getPDFAccion(idAccion).subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      const url= window.URL.createObjectURL(blob);

      let myWindow = window.open("text/html", "_blank");
      myWindow.document.open();
      myWindow.document.write('<html><head><title>'+this.accion.accion.nombre+' - CUS N° '+this.accion.numAccion+'</title></head><body style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"><embed type="application/pdf" src="' + url + '" height="100%" width="100%"></embed></body></html>');
      myWindow.document.close();
    },error => {
      console.log(error);
    })
  }

  submitFirma(firmaForm:FormGroup) {
    this.loading = true;
    this.modalsService.postSign(firmaForm.getRawValue()).subscribe(data => {
      this.toastr.success('El documento se firmó correctamente','=)' ,{
        progressBar:true,
        positionClass: 'toast-bottom-right',
      });
      this.onClose.next(data);
      this.loading = false;
      this.gde = data;
    },error => {
      this.toastr.error('Se ha producido un error','Error =(' ,{
        progressBar:true,
        positionClass: 'toast-bottom-right',
      });
      this.onClose.next(null);
      this.bsModalRef.hide();
      console.log(error);
    })
  }

  ngOnInit() {
    this.firmaForm = this.fb.group({
      destinatario:[,Validators.required],
      detalle:[false,Validators.required],
      idAccion:[this.accion._id]
    });
    this.loadSearcher();
  }

}
