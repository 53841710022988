import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TokenProvider } from '../providers/token.provider';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(private router:Router,
                private tokenProvider:TokenProvider) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        const tokenData = this.tokenProvider.getTokenData();
        const tokenExpiration = this.tokenProvider.verifyTokenExpiration();
        if(tokenExpiration) {
            this.tokenProvider.deleteToken();
        }
       /*
        console.log(state.url);
        console.log(tokenData);
        console.log(tokenExpiration);
        */
        //console.log(state.url.split('/'));
        switch (state.url.split('/')[1]) {
            case '':
                if (!tokenExpiration) this.router.navigateByUrl('/inicio');
                break;
            case 'consulta':
                if (tokenExpiration) this.router.navigateByUrl('/');
                let consulta = false;
                tokenData.permisos.forEach(permiso => {
                   if(permiso.consulta) consulta = true;
                });
                if (tokenData.admin || consulta) {
                    return true;
                }else return false;
                break;
            case 'personas':
                if (tokenExpiration) this.router.navigateByUrl('/');
                let lectura = false;
                tokenData.permisos.forEach(permiso => {
                   if(permiso.lectura) lectura = true;
                });
                if (tokenData.admin || lectura) {
                    return true;
                }else return false;
                break;
            case 'puntosdeconsulta':
                if (tokenExpiration) this.router.navigateByUrl('/');
                if (tokenData.admin) {
                    return true;
                }else return false;
                break;
            case 'acciones':
                if (tokenExpiration) this.router.navigateByUrl('/');
                if (tokenData.admin) {
                    return true;
                }else return false;
                break;
            case 'normas':
                if (tokenExpiration) this.router.navigateByUrl('/');
                if (tokenData.admin) {
                    return true;
                }else return false;
                break;
            case 'usuarios':
                if (tokenExpiration) this.router.navigateByUrl('/');
                //check responsable
                let responsable = false;
                tokenData.permisos.forEach(permiso => {
                   if(permiso.responsable) responsable = true;
                });
                if (tokenData.admin || responsable) {
                    return true;
                }else return false;
                break;
        }
        return true;
    }
}
